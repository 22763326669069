import React, { useEffect, useState } from "react";
import TopBrandsTable from "./TopBrands";
import TopProdTable from "./TopProd";
import TopCategoriesTable from "./TopCategory";
import { Blocks } from "./Blocks";
import { User } from "../user/User";
import UserTable from "./UserTable";
import { Upblock } from "./Upblock";
import { Downblock } from "./Downblock";
import OrdersTable from "./OrdersTable";
import { getToken } from "../../hook/getToken";

export const Dashboard = () => {
  const [orderData, setOrderData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = getToken();
        const headers = {
          Authorization: `Bearer ${accessToken}`,
        };

        const response = await fetch(
          `${process.env.REACT_APP_URL}/v1/admin/get-product-order-count`,
          {
            headers: headers,
          }
        );

        const data = await response.json();
        setOrderData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="pr-5">
      <div className="flex justify-between pt-8">
        <p className=" text-2xl font-semibold">Dashboard</p>
        <div>
          <User />
        </div>
      </div>
      <div className=" pt-8">
        <Upblock orderData={orderData}/>
      </div>
      <div className=" pt-8">
        <Blocks orderData={orderData}/>
      </div>
      <div className=" pt-8">
        <Downblock orderData={orderData}/>
      </div>
      <div className="flex justify-between gap-2  pt-8">
        <TopBrandsTable />
        <TopCategoriesTable />
      </div>
      <div>
        <OrdersTable />
      </div>
      <div className="flex justify-between gap-2  py-8">
        <TopProdTable />
        <UserTable />
      </div>
    </div>
  );
};
