import React, { useEffect, useState } from "react";
import SplashScreenModal from "./SplashScreenModal";
import PreviewModal from "./PreviewModal";

const Application = () => {
  const [screenData, setScreenData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [selectedItemId, setSelectedItemId] = useState(null); // Keep track of the selected item's id

  const handleClose = () => {
    setShowModal(false);
    setPreviewModal(false);
    setSelectedItemId(null); // Clear the selected item when closing the modal
  };

  // Fetch data from API
  const fetchScreen = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/v1/app/splash-screen/get`
      );
      const data = await response.json(); // Parse the response as JSON
      setScreenData(data.data); // Store the fetched data in the state
    } catch (error) {
      console.error("Error fetching screen data:", error);
    }
  };

  // Use useEffect to fetch data on component mount
  useEffect(() => {
    fetchScreen();
  }, []);

  const handleAddClick = () => {
    setModalType("add");
    setShowModal(true);
  };

  const handleEditClick = (id) => {
    setSelectedItemId(id); // Set the selected item's id
    setModalType("edit");
    setShowModal(true);
  };

  const handleStatusChange = async (id, newStatus) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/v1/app/splash-screen/status/${id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ active: newStatus === "Active" }), // Set active based on the selected option
        }
      );

      if (response.ok) {
        // Update the UI after successful toggle
        setScreenData((prevData) =>
          prevData.map((item) =>
            item._id === id ? { ...item, active: newStatus === "Active" } : item
          )
        );
      } else {
        console.error("Error updating status:", response.statusText);
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  return (
    <div>
      <div className="flex justify-between my-4">
        <h1 className="text-lg font-semibold">Application Splash Screen</h1>
        <div className=""></div>
      </div>
      <div className="flex justify-between">
        <button className="text-sm font-semibold" onClick={handleAddClick}>
          <span className="">+</span>Add splash screen
        </button>
        {showModal && (
          <SplashScreenModal
            modalName={modalType} // Dynamically set the modal type
            onClose={handleClose}
            id={selectedItemId} // Pass the selected id for edit
          />
        )}
        <button
          className="text-sm text-white bg-blue-800 px-3 py-1 mr-4 rounded-sm"
          onClick={() => setPreviewModal(true)}
        >
          Preview
        </button>
      </div>
      {previewModal && <PreviewModal onClose={handleClose} />}
      <div className="relative overflow-x-auto p-5">
        <table className="w-full text-left text-xs">
          <thead className="text-xs uppercase text-gray-900 font-semibold border-b border-solid border-gray-200">
            <tr>
              <th scope="col" className="px-4 py-3">
                Sl No
              </th>
              <th scope="col" className="px-4 py-3">
                Image
              </th>
              <th scope="col" className="px-4 py-3">
                Description
              </th>
              <th scope="col" className="text-center px-4 mx-auto py-3">
                Actions
              </th>
              <th scope="col" className="text-center px-4 mx-auto py-3">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {screenData.length > 0 ? (
              screenData.map((item, index) => (
                <tr key={item._id} className="border-b border-gray-200">
                  <td className="px-4 py-3">{index + 1}</td>
                  <td className="px-4 py-3">
                    <img
                      src={item.image}
                      alt="placeholder"
                      className="w-12 h-12"
                    />
                  </td>
                  <td className="px-4 py-3">{item.description}</td>
                  <td className="text-center px-4 py-3">
                    <button onClick={() => handleEditClick(item._id)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-4 h-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                        />
                      </svg>
                    </button>
                  </td>
                  <td className="text-center px-4 py-3">
                    <select
                      value={item.active ? "Active" : "Inactive"}
                      onChange={(e) =>
                        handleStatusChange(item._id, e.target.value)
                      }
                      className={`text-xs p-2 border rounded ${
                        item.active
                          ? "bg-green-100 border-green-700"
                          : "bg-red-100 border-red-700"
                      }`}
                    >
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                    </select>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center py-3">
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Application;
